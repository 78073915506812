import './App.css';
import PuffinLogo from './images/puffinLogo.png';

const ItemThumbnail = ({src}) => (
  <div className='item-thumb'>
    <img
      alt='project thumbnail'
      src={src}
      width={128}
      height={128}
    />
  </div>
);

function App() {
  return (
    <div className="app">
      <nav className="header">
      </nav>
      <main>
        <h1>
          Snoman
          <br/>
          <span>
            Studios
          </span>
        </h1>
        <br/>
        <h2>
          Developing software you love.
        </h2>
        <div className="large-divider"/>
        <h3>
          Software
        </h3>
        <div className='info-box'>
          <div className='info-box-inner'> 
            <ItemThumbnail src={PuffinLogo} />
            <div className='info-box-inner-right'>
              <h4>Puffin Dating</h4>
              <br/>
              <p>
                Puffin brings people together from all over the world, based on their interests, not looks. 
                <br/>
                <br/>
                Launching soon.
              </p>
            </div>
          </div>
          <div class='app-images'>
            <a
              target="_blank"
              rel="noreferrer"
              className='cool-button'
              href="https://www.puffin.dating/">
                Go to Puffin.Dating
            </a>
          </div>
        </div>
      </main>
      <footer>
        SNOMAN STUDIOS LLC
        <br/>
        COPYRIGHT 2022 ALL RIGHTS RESERVED
        <br/>
        <a href="/privacy.html" >Terms of Service and Privacy Policy</a>
      </footer>
    </div>
  );
}

export default App;
